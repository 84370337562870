.wysiwyg {

    p, h3, ul, ol {
        margin-bottom: calc((22/16) * 1em);
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
        @media (hover: hover) and (pointer: fine) {
            @supports (text-decoration-color: transparent) {
                text-decoration-color: currentColor;
                transition: text-decoration-color 0.15s;
                &:hover {
                    text-decoration: underline !important;
                    text-decoration-color: transparent !important;
                }
            }
        }
    }

    b, strong, h3 {
        @apply font-medium;
    }

    i, em {
        @apply italic;
    }

    h3:not(:first-child),
    p:not(:first-child) strong:first-child:last-child {
        display: inline-block;
        margin-top: calc((22/16) * 0.5em);
    }

    ul {
        list-style: disc outside;
        padding-left: 1em;
    }

    ol {
        list-style: decimal outside;
        padding-left: 1em;
    }

    li {
        display: list-item;
        padding-left: 0.5em;
    }

    ul li {
        list-style-type: disc;
    }
}
