:root {
    // Fix for Tailwind 3 being a bit broken on older Safaris
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-in-cubic;
}

.lazyload.lazyzoom, .lazyloading.lazyzoom {
    transform: scale(1.02, 1.02) translate(0, 1vh);
    transform-origin: center bottom;
}

.lazyloaded.lazyzoom {
    transform: scale(1, 1) translate(0, 0);
    transform-origin: center bottom;
    transition: opacity 0.5s theme('transitionTimingFunction.in-sine'), transform 1s theme('transitionTimingFunction.out-quad');
}

.no-js img.lazyload[data-src],
.no-js img.lazyload[data-srcset] {
    display: none;
}

svg {
    display: block;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

button, a {
    touch-action: manipulation;
    border-radius: 0;

    &[disabled],
    &[aria-disabled] {
        cursor: default;
    }
}

button {
    appearance: none;
    font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none;
}

html.outline,
html.no-outline * {
    outline: none !important;
}

select {
    appearance: none;
}

::-moz-selection {
    background: rgba(42, 42, 55, 0.99);
    color: rgba(255, 255, 255, 1);
}

::selection {
    background: rgba(42, 42, 55, 0.99);
    color: rgba(255, 255, 255, 1);
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

table {
    th, td {
        text-align: left;
        padding: 0;
        font-weight: normal;
        vertical-align: top;
    }
}

strong, b {
    font-weight: normal;
}

i, em {
    font-style: italic;
}

.hit::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    vertical-align: bottom;
}

img, video, svg {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

details > summary {
    list-style: none;
    cursor: pointer;
}

details > summary > * {
    display: inline;
}

details > summary::-webkit-details-marker {
    display:none;
}

.padded-section {
    @apply w-full pt-68 pb-48 m:pt-80 m:pb-64 l:pt-104 l:pb-80 xl:pt-120 xl:pb-104;
}

.content-block {
    @apply w-full my-40 m:my-64 l:my-80 xl:my-104;
}

@media (hover: hover) and (pointer: fine) {
    @supports (text-decoration-color: transparent) {
        [class*="hover:underline"] {
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.15s;
            .group:hover &,
            &:hover {
                text-decoration-color: currentColor;
            }
        }
        [class*="hover:no-underline"] {
            text-decoration-color: currentColor;
            transition: text-decoration-color 0.15s;
            .group:hover &,
            &:hover {
                text-decoration: underline !important;
                text-decoration-color: transparent !important;
            }
        }
    }
}

@media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
        animation-duration: 0.00001ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.00001ms !important;
        scroll-behavior: auto !important;
    }
}
